import {React}   from 'react';
 import { BrowserRouter, Route, Switch} from 'react-router-dom';
import './App.scss';
import { Button } from '@material-ui/core';
import { faChevronDoubleRight} from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import ResumePDF from "../src/May2022-resume.pdf";
import { faGithub, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { Tooltip } from '@material-ui/core';
import { faFilePdf } from '@fortawesome/pro-solid-svg-icons';


export default function App() {
 
  return (
    <>
   <BrowserRouter>
    <main>
      <Switch>
        <Route path="/" exact component={Home} />
        <Route render={() => <h1>404: you lost</h1>} />      
      </Switch>
    </main>
    
    </BrowserRouter>
    </>
  );
}

const Home = () => (
  
  <div className='about card'>
   
      <h1 id="hello">Hello World, my name is</h1>
      <h2 id="name">Chelsea May.</h2>
      <h3>I am a
        <div class="scroller">
          <span>
            curious<br/>
            self-taught<br/>
            creative<br/>
            passionate
          </span>
        </div>
        software developer.
      </h3>

      <Button 
        size="large"
        id="contactButton" 
        variant="contained"         
        >
          <a href="mailto:chelsearmay51+dev@gmail.com">Get in touch</a>
          <FontAwesomeIcon icon={faChevronDoubleRight} size="2x"/>
      </Button>
      
        <div className='outsideLinks'>
          <Tooltip title="LinkedIn"><a href="https://www.linkedin.com/in/chelseawr/">
            <FontAwesomeIcon icon={faLinkedin} size="2x" inverse/></a>
          </Tooltip>
      
          <Tooltip title="Personal Github"><a href="https://github.com/chelseawr">
            <FontAwesomeIcon icon={faGithub} size="2x" inverse /></a>
          </Tooltip>

          <Tooltip title="Download Resume"><a href={ResumePDF} target="_blank" rel="noreferrer">
            <FontAwesomeIcon icon={faFilePdf} size="2x" inverse /></a>
          </Tooltip>
        </div>
  </div>

);
